import React from 'react'
import { Link as GLink } from 'gatsby'
import { Container, Box, Flex } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  wrapper: {
    position: `relative`,
    bg: `footerBg`
  },
  footer: {
    flexDirection: [`column-reverse`, null, null, `row`],
    justifyContent: `space-between`,
    alignItems: [`center`, null, null, `flex-start`],
    py: [4, null, null, 5],
    gap: ['2rem', null, '3rem', '7rem']
  }
}

const FooterBlock01 = ({ content: { images, collection } }) => {
  return (
    <Box sx={styles.wrapper} id={'Box'}>
      <Container px='4' id={'Container'}>
        <Flex sx={styles.footer} id={'Flex'}>
          {/*{collection?.map(*/}
          {/*  ({ text, buttons }, index) =>*/}
          {/*    buttons && (*/}
          {/*      <Box key={`item-${index}`} mb='3'>*/}
          {/*        <ContentText*/}
          {/*          content={text?.[0]}*/}
          {/*          variant='h5'*/}
          {/*          sx={{ display: [`none`, `block`] }}*/}
          {/*        />*/}
          {/*        <ContentButtons*/}
          {/*          content={buttons}*/}
          {/*          variant='vertical'*/}
          {/*          wrapperStyles={{*/}
          {/*            flexDirection: [null, `column`],*/}
          {/*            flexWrap: `wrap`,*/}
          {/*            justifyContent: `center`*/}
          {/*          }}*/}
          {/*        />*/}
          {/*      </Box>*/}
          {/*    )*/}
          {/*)}*/}
          {images.map(image => {
            return (
              <img
                src={image.src.publicURL}
                style={{
                  alignSelf: 'center',
                  width: '35vw'
                }}
              />
            )
          })}
        </Flex>
      </Container>
    </Box>
  )
}

FooterBlock01.defaultProps = {
  menuJustify: `flex-end`
}

export default WithDefaultContent(FooterBlock01)
