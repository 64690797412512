import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Tabs from '@solid-ui-components/Tabs'
import Hero from '@solid-ui-blocks/Hero/Block01'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import FeatureThree from '@solid-ui-blocks/FeaturesWithPhoto/Block01'
import Screenshot from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import Footer from '@solid-ui-blocks/Footer/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='M.I.A.' />
      {/* Modals */}
      <ModalSimple content={content['free-download-modal']} />

      <Header content={content['header-light']} menuJustify='space-between' />
      <Divider space='5' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Container id='highlights' variant='wide'>
        <Tabs space={5}>
          <FeatureOne content={content['tab-feature-one']} />
          <FeatureThree content={content['tab-feature-two']} />
          <FeatureThree content={content['tab-feature-three']} />
        </Tabs>
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Container id='enhanced-ux' variant='wide'>
        <Tabs space={3} variant='dots' position='bottom' arrows>
          <Screenshot content={content['screenshot-one']} />
          <Screenshot content={content['screenshot-two']} />
          <Screenshot content={content['screenshot-three']} />
        </Tabs>
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query myChosenQueryName {
    allBlockContent(filter: { page: { in: ["site", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
