import React, { useContext } from 'react'
import { Box, Flex, IconButton } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Modal, { ModalContext } from '@solid-ui-components/Modal'
import ListItem from '@solid-ui-components/ListItem'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentButtons from '@solid-ui-components/ContentButtons'
import ContentMap from '@solid-ui-components/ContentMap'
import ContentForm from '@solid-ui-components/ContentForm'

const ModalBlock02 = ({
  content: { identifier, text, images, map, collection, form, buttons },
  reverse
}) => {
  const hasMedia = images || map
  const { activeModal, setActiveModal } = useContext(ModalContext)
  const styles = {
    close: {
      position: `absolute`,
      top: 0,
      right: 0,
      padding: '0.4rem',
      size: [`icon.md`, null, `icon.md`],
      zIndex: 99,
      svg: {
        size: [`icon.md`, null, `icon.md`]
      },
      ':hover': {
        color: `#b0b0b0`
      }
    }
  }

  return (
    <Modal
      id={identifier}
      contentStyles={{ maxWidth: hasMedia ? `narrow` : 600, p: 0 }}
    >
      <Flex
        id={'download-modal'}
        sx={{
          alignItems: `stretch`,
          height: `90vh`,
          minHeight: '430px',
          maxHeight: `750px`,
          backgroundColor: '#e4e4e4',
          padding: '2.7rem',
          flexDirection: [
            reverse ? `column-reverse` : `column`,
            reverse ? `row-reverse` : `row`
          ]
        }}
      >
        {hasMedia && (
          <Box
            sx={{
              display: [`none`, null, `block`],
              minWidth: [null, null, null, 500],
              flex: 1,
              position: `relative`,
              borderRadius: reverse ? `right` : `left`,
              overflow: `hidden`
            }}
          >
            {images && (
              <ContentImages
                content={{ images }}
                imageFit='cover'
                height='100%'
                reverse={reverse}
              />
            )}
            {map && (
              <Reveal
                effect={reverse ? 'fadeInRight' : 'fadeInLeft'}
                style={{ width: `100%`, height: `100%` }}
              >
                <ContentMap content={map} />
              </Reveal>
            )}
          </Box>
        )}
        <Box
          sx={{
            position: 'relative',
            flex: 1,
            p: 0,
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem'
          }}
          id={'CONTENT-CONTAINER'}
        >
          {text && (
            <>
              <Box sx={{ height: 'fit-content' }} id={'SECTION1'}>
                <ContentText content={text} />
                <IconButton
                  onClick={() => setActiveModal(null)}
                  sx={styles.close}
                  aria-label='Close Modal'
                  // eslint-disable-next-line jsx-a11y/no-autofocus
                  autoFocus
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    height='48'
                    viewBox='0 96 960 960'
                    width='48'
                    fill={'#748094'}
                  >
                    <path d='m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z' />
                  </svg>
                </IconButton>
              </Box>
            </>
          )}
          {collection?.map((props, index) => (
            <ListItem key={`item-${index}`} {...props} compact />
          ))}
          {form && <ContentForm form={form} id={`form.${identifier}`} />}
          {buttons && (
            <>
              <ContentButtons content={buttons} />
            </>
          )}
        </Box>
      </Flex>
    </Modal>
  )
}

export default ModalBlock02
